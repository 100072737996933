<template>
  <div class="flex-container non-scrollable">
    <div v-if="independent" class="flex justify-content-between pl-3 pt-2 pr-3 align-items-center">
      <div class="flex align-items-center">
        <NameHeader :independent="independent" />
      </div>
    </div>
    <declare-facture class="scrollable"/>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import NameHeader from '@/components/NameHeader'
import DeclareFacture from "@/views/Pages/Porte/DeclareFacture";


export default {
  name: "DeclarationFacturation",
  components: { DeclareFacture, NameHeader},
  computed: {
    ...mapState({
      sideContent: state => state.sidebar.sideContent,
    }),
    ...mapGetters({
      independent: 'independent/getIndependent',
    }),
  },
  watch: {
    '$route.query.idIndependent': {
      handler (val) {
        if (val) this.$store.dispatch('independent/getOneIndependent', val)
      },
      deep: true
    }
  },
  async created () {
    await this.init()
  },
  methods: {
    init () {
      this.$store.dispatch('independent/getOneIndependent', this.$route.query.idIndependent)
      this.$store.dispatch('independent/getAllIndependents')
    },
    async clearIndependent () {
      let name = 'TableauDeclareFacture'
      if (this.$route.name === 'DeclareFactureAdminAdv') name = 'TableauDeclareFactureAdv'
      this.$router.push({ name })
      await this.$store.dispatch('independent/setIndependent', null)
      await this.init()
    },
  }
}
</script>

<style scoped>

</style>
